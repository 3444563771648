import Head from "next/head";

//https://css-tricks.com/essential-meta-tags-social-media/
//https://www.searchenginejournal.com/important-tags-seo/156440/#close

// Assets
import favicon from "../public/favicon.ico";

// Image for Google
// https://developers.google.com/search/docs/advanced/guidelines/google-images
// import googlePreviewImage from "../public/logo512.png";

//Open Graph image should be 1200 x 630
import openGraphImage from "../public/logo512.png";
//Twitter image should be 1200 x 630
// import twitterImage from "../public/logo512.png";

const seoImageUrl = (imageUrl) => {
  const url = imageUrl ? imageUrl : openGraphImage.src;
  return url;
};

const twitterImageUrl = (twitterImageSrc) => {
  const url = twitterImageSrc ? twitterImageSrc : openGraphImage.src;
  return url;
};

const addJsonLd = (jsonLd, type) => {
  if (jsonLd) {
    const key = `${type}-jsonld`;
    return <script type="application/ld+json" dangerouslySetInnerHTML={jsonLd} key={key} />;
  }
};

// https://jsonld.com/organization/
function addOrganizationJsonLd() {
  return {
    __html:`{
      "@context": "https://schema.org", 
      "@type": "Organization",
      "name": "Search Exhaust",
      "legalName": "Search Exhaust",
      "logo": "https://searchexhaust.com/images/logo.192.webp",
      "foundingDate": "2022",
      "url": "https://searchexhaust.com"
    }`,
  };
}

const SeoHead = (props) => (
  <Head>
    <title>{props.title}</title>
    <meta name="description" content={props.description} />
    <link rel="icon" type="image/png" href={favicon.src} />
    <link rel="canonical" href={props.canonicalLink} />
    <link rel="apple-touch-icon" href={seoImageUrl(props.imageSrc)} />

    {/* Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en_US" />
    <meta name="og:title" property="og:title" content={props.title} />
    <meta name="og:description" property="og:description" content={props.description} />
    <meta property="og:site_name" content="Search Exhaust" />
    <meta property="og:url" content={props.canonicalLink} />
    <meta property="og:image" content={seoImageUrl(props.imageSrc)} />

    {/* Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={props.title} />
    <meta name="twitter:description" content={props.description} />
    <meta name="twitter:site" content="Search Exhaust" />
    <meta name="twitter:creator" content="Search Exhaust" />
    <meta name="twitter:image" content={twitterImageUrl(props.twitterImageSrc)} />
    <meta name="twitter:image:alt" content="Logo for Search Exhaust"></meta>

    {/* JSON-LD */}
    
    {props.jsonLd && addJsonLd(props.jsonLd, "primary")}
    {addJsonLd(addOrganizationJsonLd(), "Organization")}
    {props.breadcrumbJsonLd && addJsonLd(props.breadcrumbJsonLd, "breadcrumbJsonLd")}
  </Head>
);
export default SeoHead;
